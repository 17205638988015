import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "modules"
    }}>{`Modules`}</h1>
    <hr></hr>
    <p>{`Sometimes you need to use a type which is declared in other module. So, you need a mechanism of importing/exporting types from different modules. Hegel provides ECMAScript modules like syntax for this case.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// ./modules/a.js
export class A {}
export type B = {};
export default class Main {}
export const MY_OWN_CONST = 42;
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// ./modules/b.js
import type Main, { A, B, MY_OWN_CONST } from "./b.js";

// Error: Type "22" is incompatible with type "42"
const NEW_CONST: MY_OWN_CONST = 22;
`}</code></pre>
    <p>{`As you can see, you can import type of values without actual value importing. It means that the same as other "type imports", this imports will be removed by compiler.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      